<template>
	<div class="home-wrapper">
		<el-link style="font-size: 30px;margin : 10px;">hi!{{user.username}}</el-link>
		<div style="width: 100%;heigt:120px;background-color: antiquewhite;padding-left: 15px;border-radius: 5px; ">
			<table style="height: 120px; width: 100%;margin-top: 10px; text-align: left;">
				<tr  >
					<th style="width: 13%;">已訂購服務計劃</th>
					<th style="width: 10%;">企業會員系統方案</th>
					<th style="width: 15%;"></th>
					<th></th>
					<th style="width: 13%;">服務周期</th>
					<th style="width: 35%;">06/12/2022---08/12/2022</th>
				</tr>
				
				<tr >
					<th style="width: 13%;">余额</th>
					<th style="width: 10%;">{{user.balance}}</th>
					<th style="width: 15%;"></th>
					<th></th>
					<th style="width: 13%;">企业ID</th>
					<th style="width: 35%;">{{qyid}}</th>
				</tr>
			</table>
		</div>
		
		<el-link style="font-size: 30px;margin : 10px">本月報告</el-link>
		<el-row :gutter="8">
			<el-col :xs="2" :span="5" :lg="8" v-for="item in numberConfig" :key="item.label">
				<el-card shadow="">
					<div class="label">{{item.label}}</div>
					<div class="value">{{item.value}}</div>
				</el-card>
			</el-col>
		</el-row>
		
	<!-- 	 <div style="display: flex;margin-top: 20px;">
			 <div class="kaiqidiv">
				 <div>Whatsapp客服</div>
				 <div style="display: flex;">
					 <div style="flex:2;font-size: 35px;">已開啓</div>
					 <div style="flex:1;margin-top: 10px;text-align: right;">
									<el-switch
									  v-model="value0"
									  active-color="#aa0000"
									  inactive-color="#550000">
									</el-switch></div>
				 </div>
			 </div> -->
	<!-- 		 <div  class="kaiqidiv">
				 <div>電子排隊系統</div>
				 <div style="display: flex;">
				 					 <div style="flex:2;font-size: 35px;">關閉</div>
				 					 <div style="flex:1;margin-top: 10px;text-align: right;">
				 									<el-switch
				 									  v-model="value1"
				 									  active-color="#aa0000"
				 									  inactive-color="#550000">
				 									</el-switch></div>
				 </div>
			 </div>
			 <div  class="kaiqidiv">
				<div>電子預約系統</div>
				<div style="display: flex;">
									 <div style="flex:2;font-size: 35px;">已開啓</div>
									 <div style="flex:1;margin-top: 10px;text-align: right;">
													<el-switch
													  v-model="value0"
													  active-color="#aa0000"
													  inactive-color="#550000">
													</el-switch></div>
				</div>
			 </div>
	 
		 </div> -->
		 
	<!-- 	<div style="display: flex;">
		 <el-calendar height='100px' style="flex:1;height: 100px;" v-model="value20"></el-calendar>
		 <el-calendar  style="flex:1;height: 100px;"  v-model="value2"></el-calendar>
		 <el-calendar  style="flex:1;height: 100px;"  v-model="value21"></el-calendar>
		</div> -->
	<!--	
 		<el-link style="font-size: 30px;;">接入指引</el-link>
		<div style="display: flex; width:90%;height: 200px;">
			<el-card style="flex:1;margin: 10px" shadow="always">
				<div class="label">个人Whatsapp</div>
				<div class="value"><el-button @click="setperson">进入</el-button></div>
			</el-card>		
			<el-card style="flex:1;margin: 10px"  shadow="always">
				<div class="label">企业Business API </div>
				<div class="value"><el-button @click="setqiye">进入</el-button></div>
			 
			</el-card>
		 
		</div>  
		-->
		<div  v-if="moshi=='person'" >
			
		 
			<div style="margin-top: 30px;;">
				<el-link style="font-size: 30px;;">接入个人whatsapp</el-link>
				<el-steps style="margin-top: 30px;;" :active="active">
					<el-step :title="count_page>0?'已创建':'创建店铺'" icon="el-icon-s-shop"></el-step>
					<el-step @click="jieruw()" title="接入Whatsapp" icon="el-icon-upload"></el-step>
					<el-step title="开始运行" icon="el-icon-circle-check"></el-step>
				</el-steps>
				<el-button v-if="active == 1 " style="margin-top: 12px;" @click="dialogVisible=true">创建店铺</el-button>

				<!-- <a v-if="active == 2 "  :href="'#/wlogin?whatsapp='+pageid+'&pageid='+pageid">接入whatsapp{{pageid}}</a> -->
		
			</div>
		<!-- <el-calendar v-model="value"></el-calendar> -->

			<div v-if="active == 2 " class="css-wgqwlq">
				<el-button v-if="active == 2 " style="margin-top: 12px;" @click="jieruw">{{but_txt}}</el-button>
				<div v-if="linking" class="tishi">{{ tishi }}</div>
				<div v-if="linking"> <img style="max-width: 200px;" v-if="ewm" :src="ewm" /></div>
				<div></div>
				<div class="tishi" v-if="loginfo.me">{{ loginfo.pushname }}{{ loginfo.me.user }}</div>
			</div>
		</div>


		<div  v-if="moshi=='qiye'" >
			
		 
			<div style="margin-top: 30px;;">
				<el-link style="font-size: 30px;;">接入企业whatsapp</el-link>
				<el-steps style="margin-top: 30px;;" :active="active">
					<el-step :title="count_page>0?'已创建':'创建店铺'" icon="el-icon-s-shop"></el-step>
					<el-step @click="jieruw()" title="接入API" icon="el-icon-upload"></el-step>
					<el-step title="开始运行" icon="el-icon-circle-check"></el-step>
				</el-steps>
				<el-button v-if="active == 1 " style="margin-top: 12px;" @click="dialogVisible=true">创建店铺</el-button>

				<!-- <a v-if="active == 2 "  :href="'#/wlogin?whatsapp='+pageid+'&pageid='+pageid">接入whatsapp{{pageid}}</a> -->
		
			</div>
		
			<div v-if="active == 2 " class="css-wgqwlq">
				 <el-card class="box-card">
				     <div slot="header" class="clearfix">
				         <span>接入参数 </span> <a href="">官方</a>
				     </div>
				     <el-form :model="form1">
				         <el-form-item :label="item.label" v-for="item in formConfig" :key="item.prop">
				             <el-input v-model="form1[item.prop]" clearable></el-input>
				         </el-form-item>
				     </el-form>
				       <el-button type="primary" @click="changeInfo" class="btn">{{$t('submit')}}</el-button>
				 </el-card>
			</div>
		</div>



		<!-- add renwu-->
		<el-dialog :title="$t('addDianpu')" :visible.sync="dialogVisible" :width="$store.state.isMobile? '90%' :'556px'"
		 :before-close="handleClose">
			<div class="add-template">
				<el-form ref="form" :model="form">
					<el-form-item :label="$t('listName')">
						<el-input v-model="form.name" type="textarea" :rows="2"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">{{ $t("Cancel") }}</el-button>
				<el-button type="primary" @click="adddianpu">{{$t("Sure")}}</el-button>
			</span>
		</el-dialog>



	</div>

</template>
<script>
	import {
		tongji,
		getUserinfo,changeUserinfo,
		funk
	} from '@/api'
import userinfo from '@/mixins/userinfo'
	export default {
		  mixins : [userinfo],
		data() {
			return {
				value0:true,
				value1:false,
				value2:new Date(),
				value20:new Date()-56400,
				value21:new Date()+30,
				moshi:'',
				jsq: 0,
				but_txt: '接入whatsapp', tmp_txt:'',
				linking: false,
				tishi: '',
				time: '',
				whatsapp: '',
				ewm: '',
				loginfo: [],
				form: {
					name: '',

				},
				formConfig: [
				
				    {label: this.$t('appid'), prop: 'appid', type: 'input'},
				    {label: this.$t('appsecret'), prop: 'appsecret', type: 'input'},
				    {label: this.$t('fromphone'), prop: 'fromphone', type: 'input'}
				],
				form1: {
				    name: '',appid:'',appsecret:'',fromphone:'',
				    mobile: '',
				    address: ''
				},
				dialogVisible: false,
				active: 1,
				qyid: 0,
				pageid: 0,
				count_page: 0,
				value: new Date(),
				tableData: [],
				user:[],
				numberConfig: [
					// {label: this.$t('todaytask'), value: '0'},
					{
						label: '發出信息數量',
						value: '5420'
					},
					// {label: this.$t('totalNumberOfCustomers'), value: '0'},
					{
						label: '閲讀數量',
						value: '2310'
					},
					{
						label: '發出活動項目',
						value: '120'
					},				{
						label: '會員數量',
						value: '2310'
					},				{
						label: '本月新增',
						value: '102'
					},				{
						label: '點擊數量',
						value: '898'
					},
				]
			}
		},

		created() {
			var user = JSON.parse(window.localStorage.getItem('userinfo'))
			console.log(user);
			this.user = user 
			this.qyid = user.id
			//this.getlist()
			//this.getUserinfo()
		},
		mounted() {
			window.onbeforeunload = e => {
				console.log("onloaod: ");
				clearInterval(this.timer);
			}

		},
		watch: {
		    userinfo(val) {
		        console.log('val',val)
					this.url='https://w7.khshop.online/app/index.php?i=1&c=entry&m=qicheng_messenger&do=shouquan_whatsapp&state='+val.id
		        this.form1.name = val.name || ''
		        this.form1.contract = val.contract || ''
		        this.form1.mobile = val.mobile || ''
		        this.form1.address = val.address || ''
		        this.form1.qyid = val.id
		        this.form1.appid = val.appid
		        this.form1.fromphone = val.fromphone
		        this.form1.appsecret = val.appsecret
		    },
			value20(val){
			 
				console.log('a',val);
			},
		},
		methods: {
		
			setperson(){
				// this.$message('即将开通')
				// return 
			   this.moshi='person'	
			},
			setqiye(){
			   this.moshi='qiye'	
			   var user = JSON.parse(window.localStorage.getItem('user'))
			   console.log('user:',user);
			   	
			   this.form1.username = user.username
			   this.form1.password = user.password
			   this.form1.fromphone = user.fromphone
			   this.form1.appid = user.appid
			   this.form1.appsecret = user.appsecret
			   this.getUserinfo()
			},
			jieruw() {
				this.jsq = 0
				if (this.active != 2) return

				if (!this.linking) {
					console.log("begin linnk..: ");
					this.but_txt = '正在接入'
					
					this.timer = setInterval(this.whatslogin, 2000);
				} else {
					this.but_txt = '重新接入'
					console.log("stopped: ");
					clearInterval(this.timer);
				}
				this.linking = !this.linking
				console.log('jieruw');
			},
			getUserinfo() {
				var user = JSON.parse(window.localStorage.getItem('userinfo'))
				let fdata = {
					qyid: user.id
				}
				getUserinfo(fdata).then(res => {
					if (res.code == 1) {
						console.log(res);
						window.localStorage.setItem('userinfo', JSON.stringify(res.data))
			 		this.numberConfig[1].value = res.data.nums
						this.numberConfig[2].value = res.data.lasttime
						 this.form1.appid = res.data.appid
						 this.form1.fromphone =  res.data.fromphone
					 
						 this.form1.appsecret =  res.data.appsecret
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			getlist() {
				var user = JSON.parse(window.localStorage.getItem('userinfo'))
				let fdata = {
					qyid: user.id
				}
				tongji(fdata).then(res => {
					if (res.code == 1) {
						console.log(res);
						this.tableData = res.data
						this.count_page = res.data.count_page
						if (this.count_page > 0) {
							this.active = 2
						//	this.jieruw()
						}
						this.pageid = res.data.pageid
						this.numberConfig[0].value = res.data.count_sendrwlist
						this.numberConfig[1].value = res.data.count_templatelist
						// this.numberConfig[2].value=res.data.count_daifalist

					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},

			adddianpu() {
				console.log('go taocan');
				var user = JSON.parse(window.localStorage.getItem('userinfo'))

				let fdata = {
					do: 'api_dianpu_insert',
					qyid: user.id,
					name: this.form.name,


				}

				funk(fdata).then(res => {
					if (res.code == 1) {
						console.log(res);
						location.reload()

					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})


			},
			handleClose() {
				this.dialogVisible = false;

			},

			//login w
			whatslogin() {
				this.jsq++
				var yushu = this.jsq % 3 
				var str=''
			    for (i =0 ;i<=yushu; i++){
					str+='.'
				}
				
				this.but_txt = str+ '正在接入' +str 
				if (this.jsq >= 20) {
					this.linking = false
					this.but_txt = '重新接入'
					clearInterval(this.timer);
					return
				}
				let fdata = {
					do: 'api_whatsapp_login',
					action: 'begin',
					whatsapp: this.pageid,
				}
				funk(fdata).then(res => {
					console.log(res);
					console.log(res.code);
					this.tishi = res.msg
					if (res.code < 0) {
						this.tishi = res.msg
						clearInterval(this.timer);
					}
					if (res.ewm) {
						this.ewm = res.ewm
					}
					if (res.code == 0) {
						console.log('clear..');
						this.active = 3
						clearInterval(this.timer);
						this.whatsinfo()
						// this.whatsapp=''


					}
				})

			},
			whatsinfo() {
				let fdata = {
					do: 'api_whatsapp_login',
					action: 'info',
					whatsapp: this.whatsapp,
					qyid: this.qyid,
					pageid: this.pageid

				}
				funk(fdata).then(res => {
					console.log(res);
					this.loginfo = res

				})

			},
			changeInfo() {
			    if(this.form1.appid && this.form1.appsecret  ) {
			        changeUserinfo(this.form1).then(res => {
			            if(res.code == '1'){
			                this.$message({
			                    message: res.msg,
			                    type: 'success'
			                })
			                this.getUserinfo()
						 
			            }else{
			                this.$message({
			                    message: res.msg,
			                    type: 'warning'
			                })
			            }
			        })
			    } else {
			        this.$message({
			            message: this.$t('pleaseFillInTheInformation'),
			            type: 'warning'
			        })
			    }
			}






		}

	};
</script>
<style lang="scss" scoped>
	.kaiqidiv{
		flex:1;background-color: antiquewhite;border-radius: 5px;padding: 10px;margin-right: 30px;
	}
	.home-wrapper {
		width: 100%;

		.label {
			font-size: 24px;
			font-weight: bold;
			color: rgba(0, 0, 0, .45);
		}

		.value {
			text-align: right;
			width: 100%;
			font-weight: bold;
			font-size: 50px;
			color: #ffaa00;

		}
		.btn {
			font-size: 26px;
			
			right: 2px;
			bottom: 0px;
		}
	}

	@media screen and (max-width: 768px) {
		.home-wrapper {
			.label {
				font-size: 12px;
				text-align: center;
			}

			.value {
				font-size: 14px;
			}
		}

		::v-deep .el-card__body {
			padding: 16px;
		}

		::v-deep .el-calendar-table .el-calendar-day {
			height: 40px;
		}
	}

	.css-wgqwlq {
		background-color: rgb(255, 255, 255);
		color: rgb(35, 41, 52);
		transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		border-radius: 8px;
		box-shadow: rgb(93 104 125 / 6%) 0px 0px 9px, rgb(93 104 125 / 3%) 0px 0px 1px;
		background-image: none;
		overflow: hidden;
		width: 50%;
		margin-left: 25%;
		margin-top: 1%;
		text-align: center;
	}

	.tishi {
		height: 100px;
		line-height: 100px;
		font-size: 25px;
	}
</style>
