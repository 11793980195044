<template>
    <div class="login-wrapper">
        <div class="left">
            <img src="@/assets/images/logo.jpg" alt="">
        </div>
        <div class="right">
            <div class="login">
                <component @setpanel="setpanel" :is="currentPanel" />
                <div class="checkout" @click="checkout">{{currentPanel == 'login' ? $t('signupNow') : $t('loginNow')}}</div>
            </div>
		<!-- 	 <fb-signin-button
			          :params="fbSignInParams"
			          @success="onSignInSuccess"
			          @error="onSignInError">
			    Sign in with Facebook
			  </fb-signin-button> -->
	 
        </div>
    </div>
</template>
<script>
 import {getFacebookUserinfo} from '@/api'
import Login from './components/Login'
import Register from './components/Register'
export default {
    components: {Login, Register},
    data() {
        return {
            form: {
                account: '',
                password: '',
            },
            currentPanel: 'login',
			fbSignInParams: {
			          scope: 'email,public_profile',
			          return_scopes: true
			        }
        }
    },
    methods: {
		setpanel(){
			 this.currentPanel='login'
		},
        checkout() {
             this.currentPanel = this.currentPanel == 'login' ? 'register' : 'login'
        },
		 onSignInSuccess (response) {
		        // FB.api('/me', dude => {
		        //   console.log(`Good to see you, ${dude.name}.`)
		        // })
				const token = response.authResponse.accessToken
				
		        console.log(response) //返回第三方的登录信息 tolen等
		        console.log('token',token) //返回第三方的登录信息 tolen等
				
				let  fdata= {
						token: token
					}
					 
				getFacebookUserinfo(fdata).then(res => {
					if(res.code == 1){
						const formData ={} 
						formData.username=res.data.username
						formData.password=res.data.password
					    window.localStorage.setItem('user', JSON.stringify( formData))
					    this.$store.commit('setUserInfo', res.data)
					    window.localStorage.setItem('userinfo', JSON.stringify(res.data))
					    this.$router.push('/');
					}else{
					    this.$message.error(res.msg);
					}   
				})
				
		},
		onSignInError (error) {
			
		      }

    }
};
</script>
<style lang="scss" scoped>
	.fb-signin-button {
	    /* This is where you control how the button looks. Be creative! */
	    display: inline-block;
	    padding: 4px 8px;
	    border-radius: 3px;
	    background-color: #4267b2;
	    color: #fff;
	  }

.login-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative; 
    .left{
        width: 30%;
        height: 100%;
        position: relative;
        img{
            width: 100px;
            height: 100px;
            position: absolute;
            top: 80px;
            left: 50px;
        }
    }
    .right{
        width: 54%;
        min-width: 740px;
        background: url('~@/assets/images/login-background.png')no-repeat center center /100% 100%;
        background-position: left bottom;
        height: 100%;
        position: relative;
        padding-right: 12%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        box-sizing: content-box;
        .login{
            width: 340px;
            height: auto;
            padding: 16px;
            background-color: #FFFFFF;
            border-radius: 4px;
            overflow: hidden;
            .checkout{
                width: 100%;
                margin-top: 18px;
                font-size: 12px;
                font-weight: 400;
                color: #409EFF;
                text-align: right;
                cursor: pointer;
            }
        }
    }
}
@media screen and (max-width: 768px){
    .login-wrapper .right{
        width: 80%;
        min-width: 80%;
    }
    .login-wrapper .left{
        width: 20%;
    }
    .login-wrapper .right .login{
        margin: 0 auto;
        width: 100%;
        box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
    }
    .login-wrapper .left img{
        position: fixed;
        top: 30px;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 50;
    }
    .fb-signin-button{
        padding-right: 30px;
    }
}
</style>